
          @use 'sass:math';
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        














































































.sustainability__component {
  margin-top: var(--spacing-l) !important;

  .tab-nav + &.flexible-section {
    /* stylelint-disable-next-line selector-max-compound-selectors, selector-max-combinators */
    ::v-deep .title {
      @include mq($until: l) {
        font-size: 2.6rem;
      }
    }
  }
}

.sustainability__ctas {
  margin-top: var(--spacing-l);
}
