
          @use 'sass:math';
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        




















































































































































.social-projects-grid {
  margin-top: var(--spacing-l);
}

.social-projects-grid__list {
  @extend %list-nostyle;

  @include mq(l) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: var(--spacing-m);
  }
}

.social-projects-grid__item {
  & + & {
    margin-top: var(--spacing-m);
  }

  @include mq(l) {
    & + & {
      margin-top: 0;
    }

    &:nth-child(even) {
      padding-top: var(--spacing-m);
    }
  }
}

.social-projects-card {
  @extend %border-radius-small;
  @extend %text-center;

  position: relative;
  display: flex;
  flex-direction: column;
  padding: 1.6rem;
  background: $c-white;
  box-shadow: $card-shadow;
}

.social-projects-card__title {
  margin: 1.6rem 0;
  font-size: 2rem;
  text-decoration: none;

  h2 {
    font-size: inherit;
  }

  &[href]::after {
    @include get-all-space;

    content: '';
  }

  @include mq(s) {
    margin-right: var(--spacing-m);
    margin-left: var(--spacing-m);
    font-size: 2.2rem; // h2--small
  }

  @include mq(l) {
    margin: 1.6rem var(--spacing-xs);
    font-size: 3rem; // h2--small
  }
}

.social-projects-card__text {
  a ~ & {
    margin-bottom: 0;
  }

  @include mq(s) {
    margin-right: var(--spacing-xs);
    margin-left: var(--spacing-xs);
  }
}

.social-projects-card__picture {
  @extend %border-radius-small;

  position: relative;
  order: -1;
  overflow: hidden;
  width: 100%;
  aspect-ratio: 4/3;

  ::v-deep img {
    @include image-fit;

    transition: transform 0.3s $ease-out-cubic;
    will-change: transform;
  }

  .social-projects-card__title[href]:hover ~ &,
  .social-projects-card__title[href]:focus-visible ~ & {
    /* stylelint-disable-next-line selector-max-combinators, selector-max-compound-selectors */
    ::v-deep img {
      transform: scale(1.05);
    }
  }
}

.social-projects-card__icon {
  width: 3rem;
  height: auto;
  margin: 1.6rem auto 0;
  color: $c-mustard;
  transition: transform 0.5s $ease-softer;
  will-change: transform;

  .social-projects-card__title[href]:hover ~ &,
  .social-projects-card__title[href]:focus-visible ~ & {
    transform: translateX(1rem);
  }
}

.social-projects-grid__button {
  @extend %text-center;

  margin-top: var(--spacing-m);
}
