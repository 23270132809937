
          @use 'sass:math';
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        




























































































































$padding: 0.8rem;

.tab-nav,
[class*='tab-nav--'] {
  @extend %list-nostyle;

  position: relative;
  z-index: 1;
  display: flex;
  align-items: baseline;
  width: fit-content;
  margin: 0 auto;
  padding: 0 $padding;
  gap: $padding;
  transform: translateY(-50%);
  background: $c-white;
  border-radius: 1.2rem;
  box-shadow: $card-shadow;
}

.tab-nav__item__link {
  position: relative;
  display: block;
  padding: 1.6rem;
  color: $c-medium-grey;
  line-height: normal;
  text-decoration: none;
  transition: color 0.2s $ease-out;

  &:hover,
  &:focus,
  &:active,
  &.router-link-exact-active {
    color: $c-white;
  }

  &::before,
  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: -$padding;
    width: var(--padding);
    height: 100%;
  }

  &::after {
    right: -$padding;
    left: initial;
  }

  ul:hover &.router-link-exact-active,
  ul:focus-within &.router-link-exact-active {
    color: $c-medium-grey;

    &:hover,
    &:focus {
      color: $c-white;
    }
  }
}

.tab-nav__item__background {
  --bg-left: #{$padding};
  --bg-width: 0;

  @extend %border-radius-small;

  position: absolute;
  z-index: -1;
  top: $padding;
  left: var(--bg-left);
  width: var(--bg-width);
  height: calc(100% - calc(2 * #{$padding}));
  background: $c-mustard;
  will-change: width, left;

  .tab-nav.is-ready & {
    transition: 0.2s $ease-out;
    transition-property: width, left;
  }

  @media (prefers-reduced-motion: reduce) {
    transition: none;
  }
}
